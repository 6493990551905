import React, { useState } from "react";
import "./styles/Signup.css"; // Ensure the CSS file is updated
import { Link, useNavigate } from "react-router-dom";

const Signup = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [country, setCountry] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleSignup = (e) => {
    e.preventDefault();

    if (username && password && confirmPassword && email && gender && country) {
      if (password !== confirmPassword) {
        alert("Passwords do not match!");
      } else if (!termsAccepted) {
        alert("You must accept the terms and conditions.");
      } else {
        navigate("/Dashboard"); // Redirect after successful signup
      }
    } else {
      alert("Please fill all the fields.");
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-form-container">
        <h2>Create an Account</h2>
        <p>Join us and get started</p>

        <form className="signup-form" onSubmit={handleSignup}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              placeholder="Enter your username"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              placeholder="Enter your password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              placeholder="Confirm your password"
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>

          {/* Gender Radio Buttons */}
<div className="form-group">
  <label>Gender</label>
  <div>
    <label>
      <input
        type="radio"
        name="gender"
        value="Male"
        checked={gender === "Male"}
        onChange={(e) => setGender(e.target.value)}
      />
      Male
    </label>
    <label>
      <input
        type="radio"
        name="gender"
        value="Female"
        checked={gender === "Female"}
        onChange={(e) => setGender(e.target.value)}
      />
      Female
    </label>
  </div>
</div>
{/* Country Dropdown */}
<div className="form-group">
  <label htmlFor="country">Country</label>
  <select
    id="country"
    value={country}
    onChange={(e) => setCountry(e.target.value)}
    required
  >
    <option value="">Select your country</option>
    <option value="USA">United States</option>
    <option value="Canada">Canada</option>
    <option value="UK">United Kingdom</option>
    <option value="India">India</option>
    <option value="Australia">Australia</option>
  </select>
</div>

{/* Terms and Conditions Checkbox */}
{/* Terms and Conditions Checkbox */}
<div className="form-group">
  <label className="checkbox-container">
    <input
      type="checkbox"
      checked={termsAccepted}
      onChange={(e) => setTermsAccepted(e.target.checked)}
    />
    <span>
      I accept the <Link to="/terms">terms and conditions</Link>
    </span>
  </label>
</div>



          <button type="submit" className="signup-button">
            Signup
          </button>
        </form>

        <p className="signup-footer">
          Already have an account? <Link to="/Login">Login here</Link>
        </p>
        <p className="signup-footer">
          <Link to="/">Back to Landing Page</Link>
        </p>
      </div>
    </div>
  );
};

export default Signup;
