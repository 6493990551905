





// import React from 'react';
// import './styles/Landingpage.css';
// import { Container, Row, Col, Navbar, Nav, Button, Card } from 'react-bootstrap';
// import { Link } from 'react-router-dom';

// import './styles/Landingpage.css';


// import AOS from 'aos';
//  import 'aos/dist/aos.css';
// import { useEffect } from 'react'

// const Landingpage = () => {
  
//   return (
//     <div className="landing-page">
      
//       <Navbar expand="lg" className="navbar-custom" style={{ backgroundColor: 'rgb(74, 130, 190)' }}>
//          <Container>
//            <Navbar.Brand href="#home" className="text-white px-3">Hospital Accounting</Navbar.Brand>
//            <Navbar.Toggle aria-controls="basic-navbar-nav" />
//            <Navbar.Collapse id="basic-navbar-nav">
//              <Nav className="ms-auto">
//                <Nav.Link as={Link} to="#about" className="text-white px-3">About Us</Nav.Link>
//                <Nav.Link as={Link} to="#features" className="text-white px-3">Features</Nav.Link>
//                <Nav.Link as={Link} to="#services" className="text-white px-3">Services</Nav.Link>
//                <Nav.Link as={Link} to="#testimonials" className="text-white px-3">Testimonials</Nav.Link>
//                <Nav.Link as={Link} to="#faq" className="text-white px-3">FAQs</Nav.Link>
//                <Nav.Link as={Link} to="/Signup" className="text-white px-3">Signup</Nav.Link>
//                <Nav.Link as={Link} to="/Login" className="text-white px-3">Login</Nav.Link>
//              </Nav>
//            </Navbar.Collapse>
//          </Container>
//        </Navbar>

//        <section className="hero-section">
//          <Container className="text-center text-white">
//            <h1 className="display-3 font-weight-bold">Streamline Your Hospital Accounting</h1>
//            <p className="lead mt-3">Effortlessly manage patient billing, track expenses, and generate insightful financial reports.</p>
//            <Button variant="primary" size="lg" href="#features" className="mt-3">Get Started</Button>
//          </Container>
//        </section>

//        <section className="about-section" id="about" style={{ marginTop: "5vh", marginBottom: "10vh" }}>
//          <Container>
//            <Row className="align-items-center">
//              <Col md={6} data-aos="fade-right">
//                <h2>About Us</h2>
//                <p>We are dedicated to helping healthcare facilities optimize their financial management. Our platform simplifies hospital accounting processes, ensuring accurate, efficient, and secure financial operations.</p>
//              </Col>
//              <Col md={6} className="text-center" data-aos="fade-left">
//                <img
//                  src="./static/image-1-4-595xh.jpgw3.webp"
//                  alt="About Us"
//                  className="img-fluid rounded about-img-animation"
//                />
//              </Col>
//            </Row>
//          </Container>
//        </section>





// <section className="features-section" id="features">
//   <Container>
//     <h2 className="text-center mb-5">Features</h2>
//     <Row>
//       <Col md={4} className="feature-item text-center">
//         <div className="feature-card feature-card-blue">
//           <div className="feature-icon bg-blue">
//             <i className="fas fa-file-invoice-dollar"></i> 
//           </div>
//           <h4>Patient Billing</h4>
//           <p>Manage patient bills with ease, ensuring transparency and accuracy in all transactions.</p>
//         </div>
//       </Col>
//       <Col md={4} className="feature-item text-center">
//         <div className="feature-card feature-card-green">
//           <div className="feature-icon bg-green">
//             <i className="fas fa-wallet"></i> 
//           </div>
//           <h4>Expense Tracking</h4>
//           <p>Track hospital expenses in real-time, optimizing budget allocation and resource management.</p>
//         </div>
//       </Col>
//       <Col md={4} className="feature-item text-center">
//         <div className="feature-card feature-card-orange">
//           <div className="feature-icon bg-orange">
//             <i className="fas fa-chart-line"></i> 
//           </div>
//           <h4>Reporting & Analytics</h4>
//           <p>Gain insights with detailed reports and analytics for better decision-making and planning.</p>
//         </div>
//       </Col>
//     </Row>
//   </Container>
// </section>






// <section className="how-it-works-section">
//   <Container>
//     <h2 className="text-center mb-5">How It Works</h2>
//     <Row className="text-center">
//       <Col md={4}>
//         <div className="how-it-works-step">
//           <div className="step-icon bg-blue">
//             <i className="bi bi-person-plus"></i>
//           </div>
//           <h4 className="step-title">Step 1</h4>
//           <p className="step-description">
//             Sign up and customize your hospital's accounting preferences.
//           </p>
//         </div>
//       </Col>
//       <Col md={4}>
//         <div className="how-it-works-step">
//           <div className="step-icon bg-green">
//             <i className="bi bi-wallet"></i>
//           </div>
//           <h4 className="step-title">Step 2</h4>
//           <p className="step-description">
//             Start managing billing, expenses, and generate real-time reports.
//           </p>
//         </div>
//       </Col>
//       <Col md={4}>
//         <div className="how-it-works-step">
//           <div className="step-icon bg-orange">
//             <i className="bi bi-graph-up-arrow"></i>
//           </div>
//           <h4 className="step-title">Step 3</h4>
//           <p className="step-description">
//             Analyze data insights to improve hospital financial strategies.
//           </p>
//         </div>
//       </Col>
//     </Row>
//   </Container>
// </section>


//       <section className="testimonials-section" id="testimonials">
//         <Container>
//           <h2 className="text-center mb-5">What Our Clients Say</h2>
//           <Row>
//             <Col md={4} className="text-center testimonial-item">
//               <p className="testimonial">"An invaluable tool for our hospital's financial management. Highly recommended!"</p>
//               <p className="client-name">— Dr. Smith, MedHealth Hospital</p>
//             </Col>
//             <Col md={4} className="text-center testimonial-item">
//               <p className="testimonial">"Helped us streamline billing and save time on monthly reports!"</p>
//               <p className="client-name">— Sarah Lee, Green Valley Clinic</p>
//             </Col>
//             <Col md={4} className="text-center testimonial-item">
//               <p className="testimonial">"Very user-friendly and efficient. A must-have for hospital finance teams."</p>
//               <p className="client-name">— Mike Chen, Finance Officer</p>
//             </Col>
//           </Row>
//         </Container>
//       </section>

//       <section className="contact-section m-5">
//         <Container>
//           <h2 className="text-center mb-5">Contact Us</h2>
//           <p className="text-center">Have questions or need assistance? Get in touch with our support team.</p>
//           <div className="text-center">
//             <Button variant="outline-primary" href="#contact-form">Contact Support</Button>
//           </div>
//         </Container>
//       </section>

//       <footer className="footer">
//         <Container className="text-center">
//           <Row>
//             <Col>
//               <p>&copy; 2024 Hospital Accounting App</p>
//               <Nav className="justify-content-center">
//                 <Nav.Link href="#privacy" className="text-white">Privacy Policy</Nav.Link>
//                 <Nav.Link href="#terms" className="text-white">Terms of Service</Nav.Link>
//               </Nav>
//             </Col>
//           </Row>
//         </Container>
//       </footer>
//     </div>
//   );
// };

// export default Landingpage;



import React from 'react';
import './styles/Landingpage.css';
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Card,
  Carousel,
  Accordion,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AOS from 'aos';
// import 'aos/dist/aos.css';
import { useEffect,useState } from 'react';

const Landingpage = () => {


  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      offset: 50,     // Offset for triggering animations
      easing: 'ease-in-out', // Animation easing
      once: true,
          
    });
  }, []);
  const toggleQuestion = (question) => {
    setOpenQuestion(openQuestion === question ? null : question);
  };

  const [openQuestion, setOpenQuestion] = useState(null);

  return (
    <div className="landing-page">
      <Navbar expand="lg" className="navbar-custom" style={{ backgroundColor: 'rgb(74, 130, 190)' }}>
        <Container>
          <Navbar.Brand href="#home" className="text-white px-3">Hospital Accounting</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="#about" className="text-white px-3">About Us</Nav.Link>
              <Nav.Link as={Link} to="#features" className="text-white px-3">Features</Nav.Link>
              <Nav.Link as={Link} to="#services" className="text-white px-3">Services</Nav.Link>
              <Nav.Link as={Link} to="#testimonials" className="text-white px-3">Testimonials</Nav.Link>
              <Nav.Link as={Link} to="#faq" className="text-white px-3">FAQs</Nav.Link>
              <Nav.Link as={Link} to="/Signup" className="text-white px-3">Signup</Nav.Link>
              <Nav.Link as={Link} to="/Login" className="text-white px-3">Login</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Hero Section */}
      <section className="hero-section">
        <Container className="text-center text-white">
          <h1 className="display-3 font-weight-bold">Streamline Your Hospital Accounting</h1>
          <p className="lead mt-3">Effortlessly manage patient billing, track expenses, and generate insightful financial reports.</p>
          <Button variant="primary" size="lg" href="#features" className="mt-3">Get Started</Button>
        </Container>
      </section>

      {/* About Section */}
      <section className="about-section" id="about" style={{ marginTop: "5vh"}}>
        <Container>
          <Row className="align-items-center">
            <Col md={6} data-aos="fade-right">
              <h2>About Us</h2>
              <p>We are dedicated to helping healthcare facilities optimize their financial management. Our platform simplifies hospital accounting processes, ensuring accurate, efficient, and secure financial operations.Our platform simplifies hospital accounting processes, ensuring accurate, efficient, and secure financial operations.</p>
            </Col>
            <Col md={6} className="text-center" data-aos="fade-left">
              <img
                src="./static/image-1-4-595xh.jpgw3.webp"
                alt="About Us"
                className="img-fluid rounded about-img-animation"
              />
            </Col>
          </Row>
        </Container>
      </section>

      {/* Features Section */}
      <section className="features-section" id="features">
        <Container>
          <h2 className="text-center mb-5">Features</h2>
          <Row>
            <Col md={4} className="feature-item text-center">
              <div className="feature-card feature-card-blue" data-aos="zoom-in">
                <div className="feature-icon bg-blue">
                  <i className="fas fa-file-invoice-dollar"></i>
                </div>
                <h4>Patient Billing</h4>
                <p>Manage patient bills with ease, ensuring transparency </p>
              </div>
            </Col>
            <Col md={4} className="feature-item text-center">
              <div className="feature-card feature-card-green" data-aos="zoom-in">
                <div className="feature-icon bg-green">
                  <i className="fas fa-wallet"></i>
                </div>
                <h4>Expense Tracking</h4>
                <p>Track hospital expenses in real-time, optimizing budget allocation and resource </p>
              </div>
            </Col>
            <Col md={4} className="feature-item text-center">
              <div className="feature-card feature-card-orange" data-aos="zoom-in">
                <div className="feature-icon bg-orange">
                  <i className="fas fa-chart-line"></i>
                </div>
                <h4>Reporting & Analytics</h4>
                <p>Gain insights with detailed reports and analytics for better decision-making </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Services Section */}
      {/* Services Section */}

<section
  className="services-section"
  id="services"
  style={{ backgroundColor: '#f8f9fa', padding: '50px 20px' }}
>
  <Container>
    <h2 className="text-center mb-5">Our Services</h2>
    <Row>
      <Col md={4} data-aos="fade-up" className="mb-4">
        <Card className="text-center shadow-sm">
          <Card.Body>
            <div className="service-icon mb-3">
              <i className="fas fa-laptop-medical fa-2x text-primary"></i>
            </div>
            <Card.Title>Digital Patient Records</Card.Title>
            <Card.Text>
              Streamline patient information management with secure and organized digital records.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4} data-aos="fade-up" data-aos-delay="200" className="mb-4">
        <Card className="text-center shadow-sm">
          <Card.Body>
            <div className="service-icon mb-3">
              <i className="fas fa-shield-alt fa-2x text-success"></i>
            </div>
            <Card.Title>Advanced Security</Card.Title>
            <Card.Text>
              Protect sensitive hospital data with top-notch encryption and security protocols.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4} data-aos="fade-up" data-aos-delay="400" className="mb-4">
        <Card className="text-center shadow-sm">
          <Card.Body>
            <div className="service-icon mb-3">
              <i className="fas fa-chart-pie fa-2x text-warning"></i>
            </div>
            <Card.Title>Analytics & Reporting</Card.Title>
            <Card.Text>
              Leverage real-time data insights to make informed financial decisions.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <Row>
      <Col md={4} data-aos="fade-up" data-aos-delay="600" className="mb-4">
        <Card className="text-center shadow-sm">
          <Card.Body>
            <div className="service-icon mb-3">
              <i className="fas fa-hand-holding-usd fa-2x text-info"></i>
            </div>
            <Card.Title>Expense Optimization</Card.Title>
            <Card.Text>
              Minimize overhead costs and maximize efficiency through budget tracking.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4} data-aos="fade-up" data-aos-delay="800" className="mb-4">
        <Card className="text-center shadow-sm">
          <Card.Body>
            <div className="service-icon mb-3">
              <i className="fas fa-file-invoice fa-2x text-danger"></i>
            </div>
            <Card.Title>Invoice Management</Card.Title>
            <Card.Text>
              Simplify invoice creation and management with automated billing systems.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4} data-aos="fade-up" data-aos-delay="1000" className="mb-4">
        <Card className="text-center shadow-sm">
          <Card.Body>
            <div className="service-icon mb-3">
              <i className="fas fa-users-cog fa-2x text-secondary"></i>
            </div>
            <Card.Title>Custom Integrations</Card.Title>
            <Card.Text>
              Seamlessly integrate our platform with your hospital’s existing systems.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
</section>


<section
      className="faq-section"
      id="faq"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 auto",
        marginBottom: "15vh",
        width: "70%",
        flexDirection: "column",
      }}
    >
      <p
        className="text-center mb-5"
        style={{
          marginBottom: "5vh",
          fontSize: "2rem",
        }}
      >
        Frequently Asked Questions
      </p>
      <div
        style={{
          width: "100%",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        {/* Question 1 */}
        <div>
          <button
            onClick={() => toggleQuestion(1)}
            style={{
              width: "100%",
              textAlign: "left",
              padding: "10px",
              color: "black",
              border: "none",
              cursor: "pointer",
              fontWeight: "bold",
              position: "relative", // Allows absolute positioning of arrow
            }}
          >
             <p
        className=" "
        style={{
          
          fontSize: "1rem",
        }}
      >
        How secure is your platform?
      
            
            <span
              style={{
                position: "absolute",
                right: "10px",
                transform: openQuestion === 1 ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            >
              ▼
            </span>
            </p>
          </button>
          {openQuestion === 1 && (
            <div
              style={{
                marginTop: "10px",
                padding: "10px",
                backgroundColor: "beige",
                border: "1px solid coral",
                borderRadius: "5px",
              }}
            >
              <p>
              We use advanced encryption and security protocols to ensure data
              privacy and security. </p>
            </div>
          )}
        </div>

        {/* Question 2 */}
        <div style={{ marginTop: "10px" }}>
          <button
            onClick={() => toggleQuestion(2)}
            style={{
              width: "100%",
              textAlign: "left",
              padding: "10px",
              color: "black",
              border: "none",
              cursor: "pointer",
              fontWeight: "bold",
              position: "relative", // Allows absolute positioning of arrow
            }}
          >
             <p
        
        style={{
         
          fontSize: "1rem",
        }}
      >
        Can this integrate with our existing system?
      
            
            <span
              style={{
                position: "absolute",
                right: "10px",
                transform: openQuestion === 2 ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            >
              ▼
            </span>
            </p>
          </button>
          {openQuestion === 2 && (
            <div
              style={{
                marginTop: "10px",
                padding: "10px",
                backgroundColor: "beige",
                border: "1px solid coral",
                borderRadius: "5px",
              }}
            >
              <p>
              Yes, our platform offers easy integration with most hospital
              management systems. </p>
            </div>
          )}
        </div>
      </div>
    </section>



      {/* Footer */}
      <footer className="footer">
        <Container className="text-center">
          <Row>
            <Col>
              <p>&copy; 2024 Hospital Accounting App</p>
              <Nav className="justify-content-center">
                <Nav.Link href="#privacy" className="text-white">Privacy Policy</Nav.Link>
                <Nav.Link href="#terms" className="text-white">Terms of Service</Nav.Link>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default Landingpage;
