

import React, { useState,useEffect, useRef } from 'react';
import './styles/Dashboard.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Container, Row, Col, Card, Modal, Button, Form } from 'react-bootstrap';
import Chart from 'chart.js/auto';
import moment from 'moment';
import { Link,useNavigate } from 'react-router-dom';

function Dashboard() {
    const [style, setStyle] = useState("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ");
    const areaChartRef = useRef(null);
    const pieChartRef = useRef(null);
    const [showEventModal, setShowEventModal] = useState(false);const [events, setEvents] = useState([]);
    const [newEvent, setNewEvent] = useState({ title: '', start: '', end: '' });
    // Area Chart Effect
    useEffect(() => {
        const ctx = document.getElementById('myAreaChart1').getContext('2d');

        // Destroy existing chart if it exists
        if (areaChartRef.current) {
            areaChartRef.current.destroy();
        }

        // Create new chart instance
        areaChartRef.current = new Chart(ctx, {
            type: 'line',
            data: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June'],
                datasets: [{
                    label: 'Total Revenue',
                    data: [5000, 10000, 15000, 20000, 25000, 30000],
                    backgroundColor: 'rgba(78, 115, 223, 0.1)',
                    borderColor: 'rgba(78, 115, 223, 1)',
                    borderWidth: 2,
                    tension: 0.4,
                    fill: true,
                }],
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    legend: {
                        display: true,
                        position: 'top',
                    },
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                    },
                    y: {
                        beginAtZero: true,
                        ticks: {
                            callback: function (value) {
                                return `₹${value}`;
                            },
                        },
                    },
                },
            },
        });

        // Cleanup function
        return () => {
            if (areaChartRef.current) {
                areaChartRef.current.destroy();
            }
        };
    }, []);

    // Pie Chart Effect
    useEffect(() => {
        const ctx = document.getElementById('myPieChart1').getContext('2d');

        // Destroy existing chart if it exists
        if (pieChartRef.current) {
            pieChartRef.current.destroy();
        }

        // Create new chart instance
        pieChartRef.current = new Chart(ctx, {
            type: 'pie',
            data: {
                labels: ['Cardiology', 'Orthopedics', 'Radiology', 'Neurology', 'Pediatrics'],
                datasets: [{
                    data: [50000, 40000, 30000, 20000, 10000],
                    backgroundColor: ['#4e73df', '#1cc88a', '#36b9cc', '#f6c23e', '#e74a3b'],
                    hoverBackgroundColor: ['#2e59d9', '#17a673', '#2c9faf', '#f4b619', '#e02d1b'],
                    hoverBorderColor: 'rgba(234, 236, 244, 1)',
                }],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: 'top',
                    },
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            const dataset = data.datasets[tooltipItem.datasetIndex];
                            const currentValue = dataset.data[tooltipItem.index];
                            return `$${currentValue.toLocaleString()}`;
                        },
                    },
                },
            },
        });

        // Cleanup function
        return () => {
            if (pieChartRef.current) {
                pieChartRef.current.destroy();
            }
        };
    }, []);
    

    const changeStyle = () => {
        if (style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
        {
            setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled");
        }
        else{
            setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
        }
    };
    // const changeStyle1 = () => {
    //     if (style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
    //     {
    //         setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1");
    //     }
    //     else{
    //         setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion")
    //     }
    // };
    const handleEventSubmit = () => {
         setEvents([...events, { ...newEvent, start: new Date(newEvent.start), end: new Date(newEvent.end) }]);
             setShowEventModal(false);
             setNewEvent({ title: '', start: '', end: '' });
           };
           const localizer = momentLocalizer(moment);

           const navigate = useNavigate();

    const handleLogout = () => {
        // Perform any logout actions, such as clearing tokens or user data
        
        navigate('/Landingpage'); // Redirect to the landing page
    };

    return (
        <div>
            <body id="page-top">

                {/*  <!-- Page Wrapper --> */}
                <div id="wrapper">

                    {/*  <!-- Sidebar --> */}
                    <ul className={style} id="accordionSidebar">

                        {/*  <!-- Sidebar - Brand --> */}
                        <a className="sidebar-brand d-flex align-items-center justify-content-center" href="#">
                            <div className="sidebar-brand-icon rotate-n-15">
                                <i className="fas fa-hospital"></i>
                            </div>
                            <div className="sidebar-brand-text mx-3">AB Hospital <sup></sup></div>
                            <div className="text-center d-none d-md-inline">
                            <button className="rounded-circle border-0" id="sidebarToggle" onClick={changeStyle}></button>
                        </div>
                        </a>

                        {/*   <!-- Divider --> */}
                        <hr className="sidebar-divider my-0" />

                        {/*  <!-- Nav Item - Dashboard --> */}
                        <li className="nav-item active">
                            <a className="nav-link">
                                <i className="fas fa-fw fa-tachometer-alt"></i>
                                <span>Dashboard</span></a>
                        </li>

                        {/*  <!-- Divider --> */}
                        <hr className="sidebar-divider" />

                        {/*   <!-- Heading --> */}
                        <div className="sidebar-heading">
                            Interface
                        </div>

                        {/*  <!-- Nav Item - Pages Collapse Menu --> */}
                        <li className="nav-item">
                            <a className="nav-link collapsed"  data-toggle="collapse" data-target="#collapseTwo"
                                aria-expanded="true" aria-controls="collapseTwo">
                                <i className="fas fa-fw fa-cog"></i>
                                <span>Deparrtments</span>
                            </a>
                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    {/* <h6 className="collapse-header">Custom Components:</h6> */}
                                    <a className="collapse-item" >Cardiology</a>
                                    <a className="collapse-item" >Neurology</a>
                                </div>
                            </div>
                        </li>

                        {/* <!-- Nav Item - Utilities Collapse Menu --> */}
                        <li className="nav-item">
                            <a className="nav-link collapsed"  data-toggle="collapse" data-target="#collapseUtilities"
                                aria-expanded="true" aria-controls="collapseUtilities">
                                <i className="fas fa-fw fa-wrench"></i>
                                <span>Patient Records</span>
                            </a>
                            <div id="collapseUtilities" className="collapse" aria-labelledby="headingUtilities"
                                data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">Inventory</h6>
                                    <a className="collapse-item" >Medicine Stock</a>
                                    <a className="collapse-item" >Equipment</a>
                                    <a className="collapse-item" >Supplies</a>
                                    <a className="collapse-item" >Other</a>
                                </div>
                            </div>
                        </li>

                        {/*  <!-- Divider --> */}
                        <hr className="sidebar-divider" />

                        {/* <!-- Heading --> */}
                        <div className="sidebar-heading">
                            Addons
                        </div>

                        {/*  <!-- Nav Item - Pages Collapse Menu --> */}
                        <li className="nav-item">
                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages"
                                aria-expanded="true" aria-controls="collapsePages">
                                <i className="fas fa-fw fa-folder"></i>
                                <span>Staff Management</span>
                            </a>
                            <div id="collapsePages" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">HR Management:</h6>
                                    <a className="collapse-item" >Staff Profiles</a>
                                    <a className="collapse-item">Payroll Management</a>
                                    <a className="collapse-item" >Shift Scheduling</a>
                                    <div className="collapse-divider"></div>
                                    <h6 className="collapse-header">Monthly Reports</h6>
                                    <a className="collapse-item" >Expense Tracking</a>
                                    <a className="collapse-item" >Salary Payments</a>
                                </div>
                            </div>
                        </li>

                        {/* <!-- Nav Item - Charts --> */}
                        <li className="nav-item">
                            <a className="nav-link" >
                                <i className="fas fa-fw fa-chart-area"></i>
                                <span>Billing & Invoices</span></a>
                        </li>

                        {/*  <!-- Nav Item - Tables --> */}
                        <li className="nav-item">
                            <a className="nav-link" >
                                <i className="fas fa-fw fa-table"></i>
                                <span>Appointments</span></a>
                        </li>

                        {/* <!-- Divider --> */}
                        <hr className="sidebar-divider d-none d-md-block" />

                         

                    </ul>
                    {/*  <!-- End of Sidebar --> */}

                    {/*  <!-- Content Wrapper --> */}
                    <div id="content-wrapper" className="d-flex flex-column">

                        {/*  <!-- Main Content --> */}
                        <div id="content">

                            {/*  <!-- Topbar --> */}
                            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                                {/*  <!-- Sidebar Toggle (Topbar) --> */}
                                <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3" onClick={changeStyle}>
                                    <i className="fa fa-bars"></i>
                                </button>

                                {/*  <!-- Topbar Search --> */}
                                <form
                                    className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                                    <div className="input-group">
                                        <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..."
                                            aria-label="Search" aria-describedby="basic-addon2" />
                                        <div className="input-group-append">
                                            <button className="btn btn-primary" type="button">
                                                <i className="fas fa-search fa-sm"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>

                                {/*  <!-- Topbar Navbar --> */}
                                <ul className="navbar-nav ml-auto">

                                    {/*  <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
                                    <li className="nav-item dropdown no-arrow d-sm-none">
                                        <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-search fa-fw"></i>
                                        </a>
                                        {/*   <!-- Dropdown - Messages --> */}
                                        <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                                            aria-labelledby="searchDropdown">
                                            <form className="form-inline mr-auto w-100 navbar-search">
                                                <div className="input-group">
                                                    <input type="text" className="form-control bg-light border-0 small"
                                                        placeholder="Search for..." aria-label="Search"
                                                        aria-describedby="basic-addon2" />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="button">
                                                            <i className="fas fa-search fa-sm"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </li>

                                    {/*  <!-- Nav Item - Alerts --> */}
                                    <li className="nav-item dropdown no-arrow mx-1">
                                        <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-bell fa-fw"></i>
                                            {/*  <!-- Counter - Alerts --> */}
                                            <span className="badge badge-danger badge-counter mb-4">3+</span>
                                        </a>
                                        {/*   <!-- Dropdown - Alerts --> */}
                                        <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                            aria-labelledby="alertsDropdown">
                                            <h6 className="dropdown-header">
                                                Alerts Center
                                            </h6>
                                            <a className="dropdown-item d-flex align-items-center" href="#">
                                                <div className="mr-3">
                                                    <div className="icon-circle bg-primary">
                                                        <i className="fas fa-file-alt text-white"></i>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="small text-gray-500">December 12, 2019</div>
                                                    <span className="font-weight-bold">Notification 1</span>
                                                </div>
                                            </a>
                                            <a className="dropdown-item d-flex align-items-center" href="#">
                                                <div className="mr-3">
                                                    <div className="icon-circle bg-success">
                                                        <i className="fas fa-donate text-white"></i>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="small text-gray-500">December 7, 2019</div>
                                                    Notification 2
                                                </div>
                                            </a>
                                            <a className="dropdown-item d-flex align-items-center" href="#">
                                                <div className="mr-3">
                                                    <div className="icon-circle bg-warning">
                                                        <i className="fas fa-exclamation-triangle text-white"></i>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="small text-gray-500">December 2, 2019</div>
                                                    Notification 3
                                                </div>
                                            </a>
                                            <a className="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
                                        </div>
                                    </li>

                                    {/*  <!-- Nav Item - Messages --> */}
                                    <li className="nav-item dropdown no-arrow mx-1">
                                        <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-envelope fa-fw"></i>
                                            {/*  <!-- Counter - Messages --> */}
                                            <span className="badge badge-danger badge-counter mb-4">7</span>
                                        </a>
                                        {/*   <!-- Dropdown - Messages --> */}
                                        <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                            aria-labelledby="messagesDropdown">
                                            <h6 className="dropdown-header">
                                                Message Center
                                            </h6>
                                            <a className="dropdown-item d-flex align-items-center" href="#">
                                                <div className="dropdown-list-image mr-3">
                                                    <img className="rounded-circle" src="img/undraw_profile_1.svg"
                                                        alt="..." />
                                                    <div className="status-indicator bg-success"></div>
                                                </div>
                                                <div className="font-weight-bold">
                                                    <div className="text-truncate">Message 1</div>
                                                    <div className="small text-gray-500"> 58m</div>
                                                </div>
                                            </a>
                                            <a className="dropdown-item d-flex align-items-center" href="#">
                                                <div className="dropdown-list-image mr-3">
                                                    <img className="rounded-circle" src="img/undraw_profile_2.svg"
                                                        alt="..." />
                                                    <div className="status-indicator"></div>
                                                </div>
                                                <div>
                                                    <div className="text-truncate">Message 2</div>
                                                    <div className="small text-gray-500"> 1d</div>
                                                </div>
                                            </a>
                                            <a className="dropdown-item d-flex align-items-center" href="#">
                                                <div className="dropdown-list-image mr-3">
                                                    <img className="rounded-circle" src="img/undraw_profile_3.svg"
                                                        alt="..." />
                                                    <div className="status-indicator bg-warning"></div>
                                                </div>
                                                <div>
                                                    <div className="text-truncate">Message 3</div>
                                                    <div className="small text-gray-500">  2d</div>
                                                </div>
                                            </a>
                                            <a className="dropdown-item d-flex align-items-center" href="#">
                                                <div className="dropdown-list-image mr-3">
                                                    <img className="rounded-circle" src="img/undraw_profile_2.svg"
                                                        alt="..." />
                                                    <div className="status-indicator bg-success"></div>
                                                </div>
                                                <div>
                                                    <div className="text-truncate">Message 4</div>
                                                    <div className="small text-gray-500">  2w</div>
                                                </div>
                                            </a>
                                            <a className="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a>
                                        </div>
                                    </li>

                                    <div className="topbar-divider d-none d-sm-block"></div>

                                    {/* <!-- Nav Item - User Information --> */}
                                    <li className="nav-item dropdown no-arrow">
                                        <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span className="mr-2 d-none d-lg-inline text-gray-600 small">Username</span>
                                            <img className="img-profile rounded-circle"
                                                src="img/undraw_profile.svg" />
                                        </a>
                                        {/*  <!-- Dropdown - User Information --> */}
                                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                            aria-labelledby="userDropdown">
                                            <a className="dropdown-item" href="#">
                                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                                Profile
                                            </a>
                                            <a className="dropdown-item" href="#">
                                                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                                Settings
                                            </a>
                                            <a className="dropdown-item" href="#">
                                                <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                                Activity Log
                                            </a>
                                            <div className="dropdown-divider"></div>
                                            <a className="dropdown-item"   onClick={handleLogout}>
                                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                                Logout
                                            </a>
                                            
                                        </div>
                                    </li>

                                </ul>

                            </nav>
                            {/*  <!-- End of Topbar --> */}

                            {/* <!-- Begin Page Content --> */}
                            <div className="container-fluid">

                                {/*  <!-- Page Heading --> */}
                                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                    <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                                    <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                                        className="fas fa-download fa-sm text-white-50"></i> Generate Report</a>
                                </div>

                                {/*  <!-- Content Row --> */}
                                <div className="row">

                                    {/*  <!-- Earnings (Monthly) Card Example --> */}
                                    <div className="col-xl-3 col-md-6 mb-4">
                                        <div className="card border-left-primary shadow h-100 py-2">
                                            <div className="card-body">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col mr-2">
                                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                            Earnings (Monthly)</div>
                                                        <div className="h5 mb-0 font-weight-bold text-gray-800">₹40,000</div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*  <!-- Earnings (Monthly) Card Example --> */}
                                    <div className="col-xl-3 col-md-6 mb-4">
                                        <div className="card border-left-success shadow h-100 py-2">
                                            <div className="card-body">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col mr-2">
                                                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                                            Earnings (Annual)</div>
                                                        <div className="h5 mb-0 font-weight-bold text-gray-800">₹215,000</div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <i className="fas fa-rupee-sign fa-2x text-gray-300"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*  <!-- Earnings (Monthly) Card Example --> */}
                                    <div className="col-xl-3 col-md-6 mb-4">
                                        <div className="card border-left-info shadow h-100 py-2">
                                            <div className="card-body">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col mr-2">
                                                        <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Tasks
                                                        </div>
                                                        <div className="row no-gutters align-items-center">
                                                            <div className="col-auto">
                                                                <div className="h5 mb-0 ml-3 font-weight-bold text-gray-800">50%</div>
                                                            </div>
                                                            {/* <div className="col">
                                                                <div className="progress progress-sm mr-2">
                                                                    <div className="progress-bar bg-info a1" role="progressbar"
                                                                    ></div>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*  <!-- Pending Requests Card Example --> */}
                                    <div className="col-xl-3 col-md-6 mb-4">
                                        <div className="card border-left-warning shadow h-100 py-2">
                                            <div className="card-body">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col mr-2">
                                                        <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                                            Pending Requests</div>
                                                        <div className="h5 mb-0 font-weight-bold text-gray-800">18</div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <i className="fas fa-comments fa-2x text-gray-300"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*  <!-- Content Row --> */}

                                <div className="row">

                                    {/*   <!-- Area Chart --> */}
                                    <div className="col-xl-8 col-lg-7">
                                        <div className="card shadow mb-4">
                                            {/*  <!-- Card Header - Dropdown --> */}
                                            <div
                                                className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                                <h6 className="m-0 font-weight-bold text-primary">Earnings Overview</h6>
                                                
                                            </div>
                                            {/*  <!-- Card Body --> */}
                                            <div className="card-body">
                                                <div className="chart-area" style={{ height: '400px' }}>
                                                    <canvas id="myAreaChart1" ></canvas>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*  <!-- Pie Chart --> */}
                                    <div className="col-xl-4 col-lg-5">
            <div className="card shadow mb-4">
                {/* Card Header */}
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">Hospital Department Income</h6>
                </div>
                {/* Card Body */}
                <div className="card-body">
                    <div className="chart-pie pt-4 pb-2" >
                        <canvas id="myPieChart1" style={{ height: '500px' }}></canvas>
                    </div>
                
                </div>
            </div>
        </div>
                                </div>

                                {/*   <!-- Content Row --> */}
                                <div className="row">
                                <div className="col-lg-6 mb-6" style={{marginTop:"-1vh"}}>
                                        <div className="card shadow mb-4">
    <div className="card-header py-3">
        <h6 className="m-0 font-weight-bold text-primary">Real-Time Analytics & Activity Monitor</h6>
    </div>
    <div className="card-body">
        <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="text-muted">Live Patient Data</div>
            <div className="font-weight-bold">120 Admitted</div>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="text-muted">Surgery Dashboard</div>
            <div className="font-weight-bold">5 In Progress</div>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="text-muted">Staff on Shift</div>
            <div className="font-weight-bold">45 Active</div>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="text-muted">ER Wait Times</div>
            <div className="font-weight-bold">Avg: 15 Min</div>
        </div>
    </div>
</div>






                                    </div>
                                    {/*   <!-- Content Column --> */}
                                    <div className="col-lg-6 mb-4">

                                        {/* <!-- Project Card Example --> */}
                                        <div className="card shadow mb-4">
    <div className="card-header py-3">
        <h6 className="m-0 font-weight-bold text-primary">Hospital Administration Overview</h6>
    </div>
    <div className="card-body">
        <h4 className="small font-weight-bold">Patient Occupancy <span className="float-right">75%</span></h4>
        <div className="progress mb-4">
            <div className="progress-bar bg-danger a2" role="progressbar" style={{ width: '75%' }}></div>
        </div>

        <h4 className="small font-weight-bold">Insurance Claims Processed <span className="float-right">50%</span></h4>
        <div className="progress mb-4">
            <div className="progress-bar bg-warning a3" role="progressbar" style={{ width: '50%' }}></div>
        </div>

        <h4 className="small font-weight-bold">Patient Records Updated <span className="float-right">65%</span></h4>
        <div className="progress mb-4">
            <div className="progress-bar bg-primary a7" role="progressbar" style={{ width: '65%' }}></div>
        </div>

        <h4 className="small font-weight-bold">Billing and Payments Processed <span className="float-right">85%</span></h4>
        <div className="progress mb-4">
            <div className="progress-bar bg-info a4" role="progressbar" style={{ width: '85%' }}></div>
        </div>

        <h4 className="small font-weight-bold">System Setup and Maintenance <span className="float-right">Complete!</span></h4>
        <div className="progress">
            <div className="progress-bar bg-success a5" role="progressbar" style={{ width: '100%' }}></div>
        </div>
    </div>
</div>


                                        {/* <!-- Color System --> */}
                                       
                                    </div>
                                    <div className="col-xl-12 col-lg-7">
                                        <div className="card shadow mb-4">
                                            {/*  <!-- Card Header - Dropdown --> */}
                                            <div
                                                className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                                <h6 className="m-0 font-weight-bold text-primary">Total Logins</h6>
                                                
                                            </div>
                                            {/*  <!-- Card Body --> */}
                                            <div className="card-body">
                                                
                                            
                                        <div className="row">
                                            <div className="col-lg-6 mb-4">
                                                <div className="card bg-primary text-white shadow">
                                                    <div className="card-body">
                                                    Doctors
                                                        <div className="text-white small">20</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="card bg-success text-white shadow">
                                                    <div className="card-body">
                                                    Nurses
                                                        <div className="text-white small">120</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="card bg-info text-white shadow">
                                                    <div className="card-body">
                                                    Accountant
                                                        <div className="text-white small">8</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="card bg-warning text-white shadow">
                                                    <div className="card-body">
                                                    Receptionist
                                                        <div className="text-white small">4</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="card bg-danger text-white shadow">
                                                    <div className="card-body">
                                                    Technicians
                                                        <div className="text-white small">12</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="card bg-secondary text-white shadow">
                                                    <div className="card-body">
                                                    Pharmacists
                                                        <div className="text-white small">17</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="card bg-light text-black shadow">
                                                    <div className="card-body">
                                                    Physiotherapist
                                                        <div className="text-black small">15</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="card bg-dark text-white shadow">
                                                    <div className="card-body">
                                                    Dietician
                                                        <div className="text-white small">5</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    </div>
                                        </div>
                                        </div>
                                    
                                    {/* <div className="col-lg-6 mb-4"> */}
                                           {/* <div className='row'>                     
                                       
                                        <div className="card shadow mb-4">
                                            <div className="card-header py-3">
                                                <h6 className="m-0 font-weight-bold text-primary">Calendar</h6>
                                            </div>
                                            <Card.Body>
                                            
                                            <Button variant="primary" onClick={() => setShowEventModal(true)}>
                                                Add Event
                                            </Button>
                                            <Calendar
                                                localizer={localizer}
                                                events={events}
                                                startAccessor="start"
                                                endAccessor="end"
                                                style={{ height: 400, marginTop: '20px' }}
                                            />
                                            </Card.Body>
                                        </div>
                                       
                                        <Modal show={showEventModal} onHide={() => setShowEventModal(false)}>
             <Modal.Header closeButton>
               <Modal.Title>Add Event</Modal.Title>
             </Modal.Header>
             <Modal.Body>
               <Form>
                 <Form.Group>
                   <Form.Label>Event Title</Form.Label>
                   <Form.Control
                     type="text"
                     value={newEvent.title}
                     onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
                  />
                 </Form.Group>
                 <Form.Group>
                   <Form.Label>Start Date and Time</Form.Label>
                   <Form.Control
                     type="datetime-local"
                     value={newEvent.start}
                     onChange={(e) => setNewEvent({ ...newEvent, start: e.target.value })}
                   />
                 </Form.Group>
                 <Form.Group>
                   <Form.Label>End Date and Time</Form.Label>
                   <Form.Control
                     type="datetime-local"
                     value={newEvent.end}
                     onChange={(e) => setNewEvent({ ...newEvent, end: e.target.value })}
                   />
                 </Form.Group>
               </Form>
             </Modal.Body>
             <Modal.Footer>
               <Button variant="secondary" onClick={() => setShowEventModal(false)}>
                 Close
               </Button>
               <Button variant="primary" onClick={handleEventSubmit}>
                 Add Event
               </Button>
             </Modal.Footer>
           </Modal>
           
           </div> */}
                                        {/* <!-- Approach --> */}
                                        <div className="row">

                                                                



                              
                                    </div>
                                </div>

                            </div>
                            {/*   <!-- /.container-fluid --> */}

                        </div>
                        {/*   <!-- End of Main Content -->

                                        <!-- Footer --> */}
                        {/* <footer className="sticky-footer" style={{backgroundColor:"#99B898"}}>
                            <div className="container my-auto">
                                <div className="copyright text-center my-auto">
                                    <span>Copyright &copy; AB Hospital 2021</span>
                                </div>
                            </div>
                        </footer> */}
                        {/* <!-- End of Footer --> */}

                    </div>
                    {/*  <!-- End of Content Wrapper --> */}

                {/* </div> */}
                {/*  <!-- End of Page Wrapper -->

                                <!-- Scroll to Top Button--> */}
                {/* <a className="scroll-to-top rounded" href="#page-top">
                    <i className="fas fa-angle-up"></i>
                </a> */}
                
                {/*  <!-- Logout Modal--> */}
                <div className="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                            <div className="modal-footer">
                                <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                                <button onClick={handleLogout} className="btn btn-primary">
                                    Logout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </body>
        </div>
    )
}

export default Dashboard;
