import React, { useState } from "react";
import "./styles/Loginpage.css"; // Make sure the CSS is updated
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = (e) => {
        e.preventDefault();

        if (username && password) {
            navigate("/Dashboard"); 
        } else {
            alert("Please enter both username and password.");
        }
    };

    return (
        <div className="login-container">
            <div className="login-form-container">
                <h2>Welcome Back</h2>
                <p>Please sign in to continue</p>

                <form className="login-form" onSubmit={handleLogin}>
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            id="username"
                            placeholder="Enter your username"
                            required
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            placeholder="Enter your password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    <button type="submit" className="login-button">Login</button>
                </form>

                <p className="login-footer">
                    Don't have an account? <Link to="/Signup">Signup here</Link>
                </p>
                <p className="signup-footer">
                        <Link to="/">Back to Landing Page</Link>
                    </p>
            </div>
        </div>
    );
};

export default Login;
